/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container, Grid, Typography, Paper} from '@mui/material';
import whyMissingLink from '../images/buyer-order-sending.jpg';
import {DefaultLayout as MDXLayout} from "../components/DefaultLayout";
export {Head} from '../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h5: "h5",
    h3: "h3",
    p: "p",
    h2: "h2",
    h6: "h6"
  }, _provideComponents(), props.components), {Block, Hero, HeroImage, HeroContent, TextGradient, ClientBar, FeatureGrid, TextPanel, TextPanelSide, TextPanelContent, Lead, Spacer, TestimonialGrid, TestimonialGridItem, CTAContactForm} = _components;
  if (!Block) _missingMdxReference("Block", true);
  if (!CTAContactForm) _missingMdxReference("CTAContactForm", true);
  if (!ClientBar) _missingMdxReference("ClientBar", true);
  if (!FeatureGrid) _missingMdxReference("FeatureGrid", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!HeroContent) _missingMdxReference("HeroContent", true);
  if (!HeroImage) _missingMdxReference("HeroImage", true);
  if (!Lead) _missingMdxReference("Lead", true);
  if (!Spacer) _missingMdxReference("Spacer", true);
  if (!TestimonialGrid) _missingMdxReference("TestimonialGrid", true);
  if (!TestimonialGridItem) _missingMdxReference("TestimonialGridItem", true);
  if (!TextGradient) _missingMdxReference("TextGradient", true);
  if (!TextPanel) _missingMdxReference("TextPanel", true);
  if (!TextPanelContent) _missingMdxReference("TextPanelContent", true);
  if (!TextPanelSide) _missingMdxReference("TextPanelSide", true);
  return React.createElement(React.Fragment, null, React.createElement(Block, {
    color: 'charcoal',
    sx: {
      backgroundColor: '#000'
    }
  }, React.createElement(Hero, null, React.createElement(HeroImage, {
    src: 'https://images.unsplash.com/photo-1510906594845-bc082582c8cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1144&q=80'
  }), React.createElement("center", null, React.createElement(HeroContent, null, React.createElement(_components.h1, null, "One connection.", React.createElement("br"), React.createElement(TextGradient, null, "Every trading partner.")), React.createElement(_components.h5, null, "Introducing Missing Link, the automated, flexible procure-to-pay solution."))))), "\n", React.createElement(Block, {
    color: 'silver',
    maxWidth: false,
    style: {
      padding: 0
    }
  }, React.createElement(ClientBar)), "\n", React.createElement(Block, {
    color: 'gradient'
  }, React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'domain_add',
      title: '35,000+ Companies'
    }, {
      icon: 'send_rounded',
      title: '89 Net Promoter Score'
    }, {
      icon: 'history',
      title: '21+ Years of Experience'
    }, {
      icon: 'monitor_heart',
      title: '99.9% Uptime'
    }]
  })), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(TextPanel, null, React.createElement(TextPanelSide, null, React.createElement("img", {
    src: whyMissingLink,
    style: {
      maxWidth: 240,
      width: '100%'
    }
  })), React.createElement(TextPanelContent, null, React.createElement(_components.h3, null, "What is Missing Link?"), React.createElement(Lead, null, React.createElement(_components.p, null, "Missing Link is a procurement-to-payment data switching provider, connecting suppliers and customers across all industries. We help our clients refine, digitalise and manage their entire procurement process, saving them time, money - and many headaches!"))))), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(FeatureGrid, {
    columns: 3,
    features: [{
      icon: 'bolt',
      title: 'One connection is all you need',
      description: 'There’s no need for you to have dedicated staff to configure, manage and support individual connections to every one of your customers and suppliers.  Connect to us and we’ll handle every procurement transaction for you.'
    }, {
      icon: 'cable',
      title: 'End-to-end experts',
      description: 'We cover the entire procurement process, from onboarding of your trading partners to daily management and support. Our skills range from business analytics and project management, to high-level technical as well as support centre staff.'
    }, {
      icon: 'task',
      title: 'Format-neutral data switching',
      description: 'Missing Link facilitates all data formats and messaging types. This means that customers and suppliers don’t have to conform to each other’s software and system requirements. Anyone can do business.'
    }, {
      icon: 'lock',
      title: 'Reduce your risk',
      description: 'With Missing Link there is no risk with regards to critical employee absenteeism or resignation, after-hour system failure or order omissions, mistakes or delays. There is also less loss, damage and theft of goods due to order inaccuracies and returns.'
    }, {
      icon: 'how_to_reg',
      title: 'Reduce your order to headcount ratio',
      description: 'Missing Link allows you to grow your business without increasing your procurement staff. Rather upskill your existing procurement staff to sales and other income generating positions.'
    }, {
      icon: 'crisis_alert',
      title: 'Exception-based reporting',
      description: 'Missing Link only lets you know about the things you care about. This keeps your inbox tidy and maintains the focus of your team.'
    }]
  })), "\n", React.createElement(Block, null, React.createElement("center", null, React.createElement(_components.h2, null, "Solutions for all phases of procurement")), React.createElement(Spacer, {
    size: 4
  }), React.createElement(FeatureGrid, {
    columns: 2,
    features: [{
      icon: 'local_atm',
      title: 'For Buyers',
      description: 'Distribute RFQs and purchase orders, publish tenders— all with business responses backed up by our call centre, operations team and EDI specialists.'
    }, {
      icon: 'shopping_cart',
      title: 'For Sellers',
      description: 'Webstore offerings, order consolidation, ordering portals, order responses and invoices.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'charcoal'
  }, React.createElement("center", null, React.createElement(_components.h3, null, "Why Missing Link?"), React.createElement(Spacer, {
    size: 4
  })), React.createElement(FeatureGrid, {
    columns: 4,
    features: [{
      icon: 'currency_pound',
      title: 'Massive cost savings'
    }, {
      icon: 'insights',
      title: 'Unrivalled speed to market'
    }, {
      icon: 'military_tech',
      title: '20 years of experience'
    }, {
      icon: 'swap_calls',
      title: 'Cross-industry & multi-standard'
    }, {
      icon: 'touch_app',
      title: 'Improved order accuracy'
    }, {
      icon: 'bolt',
      title: 'Faster order capture'
    }, {
      icon: 'forest',
      title: 'Less paper'
    }, {
      icon: 'diversity_3',
      title: 'Improved staff efficiency'
    }, {
      icon: 'psychology',
      title: 'Fewer technical staff required'
    }, {
      icon: 'question_answer',
      title: 'Fewer price queries, therefore fewer credit notes'
    }, {
      icon: 'more_time',
      title: 'Saves time'
    }, {
      icon: 'savings',
      title: 'Saves money'
    }]
  })), "\n", React.createElement(Block, {
    color: 'sky'
  }, React.createElement(FeatureGrid, {
    columns: 3,
    features: [{
      icon: 'file_copy_rounded',
      title: 'Any format',
      description: 'SAP IDOC, XML, PDF, CSV, JSON, or any custom format.'
    }, {
      icon: 'send_rounded',
      title: 'Any method',
      description: 'FTP, SFTP, FTPS, Odette, or any custom method.'
    }, {
      icon: 'business_rounded',
      title: 'Any company',
      description: 'Regardless of size, industry, standard, Missing Link has a solution.'
    }]
  })), "\n", React.createElement(Block, {
    color: 'silver'
  }, React.createElement(TestimonialGrid, {
    title: 'What our customers are saying'
  }, React.createElement(TestimonialGridItem, {
    author: 'Bosch',
    content: 'We continuously receive reliable, excellent service; customized to our needs from M2North.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Hisense',
    content: 'M2North can well support us in receiving sales orders from customers.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Bayer',
    content: 'M2North are the epitome of where there is a will, there is a way.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Engen',
    content: 'M2North makes an effort to understand their clients business, ensuring that they can offer a solution that works. Not only creating positive customer experience, but assisting in revenue growth.'
  }), React.createElement(TestimonialGridItem, {
    author: 'GSK',
    content: 'M2North, agility is their second nature.'
  }), React.createElement(TestimonialGridItem, {
    author: 'Gilbarco',
    content: 'M2North has always been a trusted and dependable service provider.'
  }))), "\n", React.createElement(Block, {
    color: 'secondary'
  }, React.createElement("center", null, React.createElement(_components.h6, null, "Are you ready to take your business to the next level?"), React.createElement(Spacer, {
    size: 2
  }), React.createElement(CTAContactForm, {
    buttonSize: 'x-large',
    defaultMessage: 'Tell me more about Missing Link'
  }))));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
